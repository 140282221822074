.error {
  color: red;
  font-size: 13px;
}

.success {
  color: #fff;
}

.form {
  width: 500px;
  margin: 0 auto;
  padding: 2rem 0;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-group label {
  color: #fff;
  padding-bottom: 10px;
}

.form-group label.required::after {
  content: '*';
  color: red;
  margin-left: 2px;
}

.form-group input[type='text'],
.form-group input[type='password'],
.form-group textarea,
.form-group .file-input {
  background: transparent;
  color: #fff;
  padding: 0.625rem 1rem;
  border: 1px solid #fff;
  border-radius: 0.3rem;
}

.form-group input[type='file'] {
  display: none;
}

.form-group .file-input {
  display: flex;
  color: #fff;
}

.form-group .file-input label {
  flex: 0 0 100%;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.form-group button[type='submit'] {
  background-color: #fff375;
  cursor: pointer;
  padding: 0.625rem 2rem;
  border-radius: 0.3rem;
  font-weight: 600;
  margin-top: 10px;
}

.listing {
  color: #fff;
  font-size: 13px;
  text-align: center;
}

.listing span {
  font-weight: bold;
}
