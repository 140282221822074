.line {
  border-bottom: 2px solid;
  border-image: repeating-linear-gradient(
    45deg,
    #f5f6f7,
    #5cdeff,
    #fea169,
    #f800c2,
    #8aff75,
    #fbf509
  );
  border-image-slice: 1;
  max-width: 1200px;
  margin: 0 auto;
}
