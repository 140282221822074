.setting {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
}

.setting div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
