.highlighted-cards {
    display: grid;
}

@media only screen and (min-width: 769px) {
    .highlighted-cards {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 20px;
        grid-row-gap: 50px;
        margin-bottom: 70px;
    }

    .highlighted-cards .card.visibility-none {
        visibility: hidden;
    }

    .highlighted-cards .card:nth-child(odd) {
        margin-top: 60px;
    }

    .highlighted-cards .card:nth-child(even) {
        margin-bottom: -50px;
    }

    .highlighted-cards .card:nth-child(1) {
        border: 3px solid #C0C0C0;
        box-shadow: 0 0 0 2px #fff, 0 0 50px 2px #C0C0C0;
    }

    .highlighted-cards .card:nth-child(1) .avatar img,
    .highlighted-cards .card:nth-child(3) .avatar img {
        height: 170px;
    }

    .highlighted-cards .card:nth-child(2) .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .highlighted-cards .card:nth-child(3) {
        border: 3px solid #CD7F32;
        box-shadow: 0 0 0 2px #fff, 0 0 50px 2px #CD7F32;
    }

    .highlighted-cards .card:nth-child(2) .avatar img {
        width: 160px;
    }

    .highlighted-cards .card:nth-child(2) .avatar {
        margin: 0 0 10px;
        height: 160px;
        width: 160px;
    }

    .highlighted-cards .card:nth-child(2) {
        flex-direction: column;
        align-items: center;
        border: 4px solid #FFB82D;
        box-shadow: 0 0 0 2px #fff, 0 0 50px 2px #FFB82D;
    }

    .highlighted-cards .card:nth-child(2) .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}

@media only screen and (max-width: 768px) {

    .highlighted-cards {
        grid-template-columns: none !important;
        grid-template-rows: auto;
        grid-row-gap: 20px !important;
        margin-bottom: 20px;
    }

    .highlighted-cards .card:nth-child(1) {
        border: 3px solid #FFB82D;
        box-shadow: 0 0 0 2px #fff, 0 0 50px 2px #FFB82D;
    }

    .highlighted-cards .card:nth-child(2) {
        border: 3px solid #C0C0C0;
        box-shadow: 0 0 0 2px #fff, 0 0 50px 2px #C0C0C0;
    }

    .highlighted-cards .card:nth-child(3) {
        border: 3px solid #CD7F32;
        box-shadow: 0 0 0 2px #fff, 0 0 50px 2px #CD7F32;
    }

}