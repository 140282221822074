.card {
    display: flex;
    flex-direction: row;
    position: relative;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    background-color: #000;
    -webkit-box-shadow: 0 0 25px 1px rgba(0, 179, 0, 0.75);
    -moz-box-shadow: 0 0 25px 1px rgba(0, 179, 0, 0.75);
    box-shadow: 0 0 25px 1px rgba(0, 179, 0, 0.75);
    cursor: pointer;
}

.card .new {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card .avatar {
    margin-right: 2rem;
    height: 130px;
    width: 140px;
}

.card .avatar .inner {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.card .avatar img {
    border-radius: 10px;
    width: 140px;
    height: 100%;
    object-fit: cover;
}

.card .description {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card .description .tags {
    display: flex;
}

.card .description .tags .tag {
    border-radius: 5px;
    background-color: #24191b;
    color: #a9a9a9;
    border: 1px solid #ffd700;
    font-size: 0.75rem;
}

@media only screen and (min-width: 769px) {
    .card {
        padding: 2rem;
    }

    .card .description .title {
        font-size: 1rem;
    }

    .card .description .info {
        font-size: 12px;
    }

    .card .description .tags .tag {
        padding: 0.625rem;
    }
}

@media only screen and (max-width: 768px) {
    .card {
        padding: 1rem;
    }

    .card .avatar {
        height: 110px;
        width: 110px;
        margin-right: 1rem;
    }

    .card .avatar img {
        width: 110px;
    }

    .card .description .title {
        font-size: 0.875rem;
    }

    .card .description .info {
        font-size: 0.625rem;
    }

    .card .description .tags .tag {
        padding: 0.375rem;
    }
}
