@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
  font-family: 'Silkscreen', sans-serif;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

body {
  background-image: url('~/public/images/background.jpg');
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.3);
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  max-width: 1200px;
  margin: auto;
  padding: 2rem 0;
  border-bottom: 2px solid #375778;
  border-image: repeating-linear-gradient(
    45deg,
    #f5f6f7,
    #5cdeff,
    #fea169,
    #f800c2,
    #8aff75,
    #fbf509
  );
  border-image-slice: 1;
}

header {
  height: 120px;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 1rem 0;
  color: #fff;
}

.nav-logo img {
  max-width: 70px;
}

.nav-logo a {
  color: #fff;
}

.nav-mid-logo {
  display: flex;
  justify-content: center;
  color: #fff;
  margin: -97px auto 0;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  list-style: none;
}

.link {
  position: relative;
  color: #fff;
}

.link .icon {
  cursor: pointer;
}

.link .disconnect {
  display: flex;
  gap: 5px;
  cursor: pointer;
}

#header .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 150px;
}

#header .content .list {
  color: #000;
  background-color: #fff375;
  cursor: pointer;
  padding: 0.625rem 2rem;
  border-radius: 0.3rem;
  font-weight: 600;
}

#header .content .search-container {
  position: relative;
}

#header .content .search-container .search {
  background: transparent;
  color: #fff;
  width: 300px;
  padding: 0.625rem 1rem;
  border: 1px solid #ffd700;
  border-radius: 0.3rem;
}

#header .content .search-container .search::placeholder {
  color: #fff;
}

#header .content .search-container .search-icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

#content .empty {
  color: #fff;
}

#content .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 50px;
}

#content .paginate {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  color: #fff;
}

#content .paginate ul {
  display: flex;
  gap: 10px;
}

#content .paginate ul li a {
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
}

#content .paginate ul li.selected a {
  background-color: #78dffd;
}

#content .paginate ul li svg {
  display: flex;
}

#content .paginate .previous:hover a,
#content .paginate .next:hover a {
  background-color: #78dffd;
  border-color: #5cdeff;
}

.settings {
  padding: 10px 0;
}

.copyright {
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  padding: 1.5rem 0;
}

@media only screen and (max-width: 768px) {
  body {
    background-size: auto;
  }

  .nav-links {
    gap: 0.5rem;
  }

  .nav-mid-logo {
    display: none;
  }

  .nav-mid-logo img {
    width: 150px;
  }

  #header .content {
    height: unset;
    gap: 20px;
    padding: 1rem 0;
  }

  .container {
    padding: 0 1rem;
  }

  .form {
    width: 100% !important;
    padding: 1rem !important;
  }

  .cards {
    grid-template-columns: none !important;
    grid-template-rows: auto;
    grid-row-gap: 20px !important;
    margin-bottom: 20px;
  }
}
